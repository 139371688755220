/* values 4 */
.corteromboclip {
  clip-path: polygon(50% 100%, 100% 75%, 100% 25%, 50% 0, 0 25%, 0 75%);

}

.corteromboclip2 {
  clip-path: polygon(50% 100%, 100% 75%, 100% 25%, 50% 0, 0 25%, 0 75%, 50% 100%, 50% 98%, 2% 74%, 2% 26%, 50% 2%, 98% 26%, 98% 74%, 50% 98%);
}


.homi {
  clip-path: polygon(0 100%, 94% 100%, 100% 0, 0 0);
}

.corteHeaderbg {
  clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%);
}

.corteHeaderbg2 {
  clip-path: polygon(100% 0, 100% 100%, 12% 100%, 0 0);
}

.textcolor2 {
  color: var(--main-color);
}

.textcolor2:hover {
  color: var(--secondary-color);
}

.bggradientdor {
  background: rgb(180, 139, 0);
  background: linear-gradient(
    90deg,
    rgba(180, 139, 0, 1) 10%,
    rgba(255, 218, 113, 1) 35%,
    rgba(255, 229, 146, 1) 55%,
    rgba(193, 152, 0, 1) 85%
  );
}
